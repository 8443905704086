<template>
  <div>
    <b-container fluid="lg">
      <div class="pageTitle mx-1">RA 資料分析</div>

      <div class="borderColor mb-3">
        <div class="d-flex align-items-center flex-wrap m-2">
          <div class="mx-2 mr-4 my-2">
            案場:
            <input
              type="text"
              name="factoryNameInput"
              list="factoryList"
              v-model="searchInput"
              class="filterInput ml-2"
              placeholder="案場名稱"
            />
            <datalist id="factoryList">
              <option
                v-for="(i, idx) in filteredFactoryInfo"
                :key="idx"
                :value="i.factory + '_' + i.factoryId"
              />
            </datalist>
          </div>

          <div class="ml-2 mr-4 my-2">
            日期:
            <date-picker
              class="ml-2"
              style="width: 150px"
              type="date"
              value-type="format"
              format="YYYY-MM-DD"
              v-model="selectedDate"
              placeholder="請選擇日期"
              :disabled-date="disabledDateAfterToday"
            />
          </div>
          <div class="ml-2 mr-4 my-2">
            時間:
            <date-picker
              class="ml-2"
              style="width: 180px"
              type="time"
              value-type="HH:mm:ss"
              format="HH:mm"
              v-model="selectedTimeRange"
              range
              placeholder="請選擇時間"
              :disabled-date="disabledDateAfterToday"
            />
          </div>
          <div class="mx-2 my-2 btnWrap">
            <button
              class="confirmButton p-1"
              style="width: 120px"
              @click="search"
              :class="{ disableButton: loadingData }"
            >
              查詢
            </button>
          </div>
          <div v-if="Object.keys(raData).length" class="mr-2 my-2 btnWrap">
            <button
              type="button"
              class="btn btn-sm text-nowrap toggleExpandBtn"
              :class="[isAllExpanded ? `btn-expand` : `btn-collapse`]"
              @click="isAllExpanded = !isAllExpanded"
            >
              {{ isAllExpanded ? "收合" : "展開" }}
            </button>
          </div>
        </div>

        <span v-if="Object.keys(raData).length">
          <div class="m-2">
            <hr class="mx-2 mt-4" />
          </div>

          <div class="m-2">
            <span class="mx-2 mb-2 text-secondary">RA 資料下載</span>
          </div>
        </span>

        <div
          v-if="Object.keys(raData).length"
          class="d-flex align-items-center flex-wrap m-2"
        >
          <div class="mx-2 mr-4 my-2">
            日期:
            <span class="ml-2 text-secondary">{{ downloadInfo[1] }}</span>
          </div>

          <div class="mx-2 mr-4 my-2 d-flex align-items-center">
            <label class="btn btn-sm btn-info m-0 text-nowrap">
              <i class="fas fa-file-upload"></i>&ensp;檔案上傳
              <input
                type="file"
                class="ml-2 border border-black rounded d-none"
                ref="inputFile"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                @change="handleChangeFiles"
              />
            </label>
            <div class="ml-2 text-secondary">{{ uploadFileName }}</div>
          </div>

          <div class="ml-2 mr-4 my-2">
            時間:
            <date-picker
              class="ml-2"
              style="width: 130px"
              type="time"
              value-type="HH:mm"
              format="HH:mm"
              v-model="selectedDownloadTime"
              placeholder="請選擇時間"
              :time-picker-options="downloadTimeRange"
            />
          </div>
          <div class="mx-2 my-2 btnWrap">
            <button
              class="confirmButton p-1"
              style="width: 120px"
              @click="download"
              :class="{ disableButton: loadingData }"
            >
              下載
            </button>
          </div>
        </div>
      </div>

      <div class="borderColor mb-4">
        <div class="tableContent">
          <!-- title -->
          <div class="d-flex text-center border tableTitle">
            <div style="width: 30%">時間</div>
            <div style="width: 20%">INV</div>
            <div style="width: 15%">PV</div>
            <div style="width: 20%">RA</div>
            <div style="width: 15%">IRR</div>
          </div>
          <!-- data -->
          <div
            v-for="(dataItem, dataKey, idx) in raData"
            :key="dataKey"
            class="d-flex justify-content-around align-items-center text-center border-bottom"
          >
            <div
              style="width: 30%"
              :aria-expanded="dataItem.isTimeExpanded"
              :aria-controls="'collapse-time-' + idx"
              class="position-relative timeText"
              @click="dataItem.isTimeExpanded = !dataItem.isTimeExpanded"
            >
              {{ dataKey }}
              <span class="expandTableBtn">
                <i
                  class="fas"
                  :class="[
                    dataItem.isTimeExpanded
                      ? 'fa-chevron-right'
                      : 'fa-chevron-down',
                  ]"
                ></i>
              </span>
            </div>
            <div style="width: 70%">
              <b-collapse
                :id="'collapse-time-' + idx"
                v-model="dataItem.isTimeExpanded"
              >
                <div
                  v-for="(invObjItem, invKey) in dataItem.invObj"
                  :key="idx + invKey"
                  class="d-flex align-items-center invWrap"
                >
                  <div
                    style="width: 28.6%; height: 50px; line-height: 50px"
                    :aria-expanded="invObjItem.isInvExpanded"
                    :aria-controls="'collapse-' + idx + invKey"
                    class="position-relative invToggle"
                    @click="
                      invObjItem.isInvExpanded = !invObjItem.isInvExpanded
                    "
                  >
                    INV {{ Number(invKey) + 1 }}
                    <span class="expandTableBtn">
                      <i
                        class="fas"
                        :class="[
                          invObjItem.isInvExpanded
                            ? 'fa-chevron-right'
                            : 'fa-chevron-down',
                        ]"
                      ></i>
                    </span>
                  </div>
                  <b-collapse
                    style="width: 71.4%"
                    :id="'collapse-' + idx + invKey"
                    v-model="invObjItem.isInvExpanded"
                  >
                    <div
                      v-for="(pvItem, idx) in invObjItem.raArray"
                      :key="idx"
                      class="d-flex pvWrap"
                    >
                      <div
                        style="width: 30%"
                        :style="[
                          invObjItem.raArray?.length === 1
                            ? { height: '50px', lineHeight: '50px' }
                            : { height: '28px', lineHeight: '28px' },
                        ]"
                      >
                        {{ pvItem.pv + 1 }}
                      </div>
                      <div
                        style="width: 40%"
                        :style="[
                          invObjItem.raArray?.length === 1
                            ? { height: '50px', lineHeight: '50px' }
                            : { height: '28px', lineHeight: '28px' },
                        ]"
                        :class="[
                          pvItem.ra > 100 || pvItem.ra < 80 ? 'raWarning' : '',
                        ]"
                      >
                        {{ pvItem.ra }}
                      </div>
                      <div
                        style="width: 30%"
                        :style="[
                          invObjItem.raArray?.length === 1
                            ? { height: '50px', lineHeight: '50px' }
                            : { height: '28px', lineHeight: '28px' },
                        ]"
                      >
                        {{ pvItem.irr }}
                      </div>
                    </div>
                  </b-collapse>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getPvRaApi } from '../api'
import { BCollapse, VBToggle } from 'bootstrap-vue'
import { raAnalysisDownload } from '../store/function/common/raAnalysisDownload'
import { disabledDateAfterToday } from '@/utils/datetime'

export default {
  name: 'RaAnalysis',
  components: {
    BCollapse
  },
  directives: { 'b-toggle': VBToggle },
  data () {
    return {
      loadingData: false,
      searchInput: '', // 奇加企業_f108
      selectedDate: '', // 'YYYY-MM-DD'
      selectedTimeRange: ['', ''], // 'HH:mm:ss'
      selectedDownloadTime: null, // 'HH:mm'
      dbRawData: [],
      raData: {},
      isAllExpanded: true,
      downloadInfo: [],
      downloadTimeRange: {
        start: '08:30',
        step: '00:01',
        end: '18:30'
      },
      uploadFile: undefined,
      uploadFileName: ''
    }
  },
  computed: {
    ...mapState(['factoryInfo']),
    filteredFactoryInfo () {
      return this.factoryInfo.filter((item) => !item.isDemoSite)
    }
  },
  watch: {
    isAllExpanded () {
      Object.values(this.raData).forEach((timeItem) => {
        if (timeItem.isTimeExpanded !== this.isAllExpanded) {
          timeItem.isTimeExpanded = this.isAllExpanded
        }
        Object.values(timeItem.invObj).forEach((invItem) => {
          if (invItem.isInvExpanded !== this.isAllExpanded) {
            invItem.isInvExpanded = this.isAllExpanded
          }
        })
      })
    }
  },
  methods: {
    ...mapActions(['asyncInfo']),
    disabledDateAfterToday,
    setDownloadTimeRange (dbData) {
      // 查詢的時間 與 api result 的時間不一定一致
      // 需用 api result 的時間 置入 可選擇下載時間，而非用 user 選擇的時間
      const dbDataFirstTime = dbData[0].timestamp.split('T')[1].slice(0, 5)
      const dbDataLastTime = dbData.at(-1).timestamp.split('T')[1].slice(0, 5)
      this.downloadTimeRange.start = dbDataFirstTime
      this.downloadTimeRange.end = dbDataLastTime
    },

    async search () {
      if (!this.searchInput) return this.$swal('請輸入案場')
      if (!this.selectedDate) return this.$swal('請選擇日期')
      if (!this.selectedTimeRange[0]) return this.$swal('請選擇時間')

      const factoryName = this.searchInput.split('_')[0]
      const fId = this.searchInput.split('_')[1]
      const timeStart = `${this.selectedDate}T${this.selectedTimeRange[0]}+08:00`
      const timeEnd = `${this.selectedDate}T${this.selectedTimeRange[1]}+08:00`

      if (!timeStart) return this.$swal('請選擇時間')

      if (new Date(timeEnd) - new Date(timeStart) > 60 * 60 * 1000) {
        return this.$swal.fire({
          title: '請選擇正確時間區間',
          text: '查詢範圍一小時(含)以內'
        })
      }

      this.loadingData = true

      const { data } = await getPvRaApi(fId, factoryName, timeStart, timeEnd)

      this.loadingData = false
      this.isAllExpanded = true

      // reset selected time
      this.selectedDownloadTime = null

      if (!data.success) return this.$swal(data.msg)
      if (!data.success && data.msg.split(' ').pop() === 'provided.') {
        return this.$swal.fire({
          title: '查詢內容有誤',
          text: '請選擇案場名稱(含編號) 或 時間',
          footer: `error msg: ${data.msg}`
        })
      }
      if (!data.data) return this.$swal(data.msg)

      // data.success
      this.dbRawData = data.data
      this.downloadInfo = [factoryName, this.selectedDate]
      this.setDownloadTimeRange(data.data)
      this.renderData(data.data)
    },

    renderData (dbData) {
      this.raData = dbData.reduce((accu, curr) => {
        let timestamp = curr.timestamp.split('T')[1]
        let invNo = curr.invNo
        let pvNo = curr.pvNo
        let RA =
          curr.RA || curr.RA === 0 ? Math.floor(curr.RA * 100) / 100 : '-'
        let IRR = curr.IRR || curr.IRR === 0 ? curr.IRR : '-'

        if (!accu[timestamp]) {
          accu[timestamp] = { isTimeExpanded: true, invObj: {} }
        }
        if (!accu[timestamp].invObj[invNo]) {
          accu[timestamp].invObj[invNo] = { isInvExpanded: true, raArray: [] }
        }

        accu[timestamp].invObj[invNo].raArray.push({
          pv: pvNo,
          ra: RA,
          irr: IRR
        })

        return accu
      }, {})
    },

    async download () {
      if (!this.uploadFile) return this.$swal('請上傳檔案')
      if (!this.selectedDownloadTime) return this.$swal('請選擇下載時間')
      if (!this.dbRawData) return this.$swal('請查詢資料再下載')

      this.loadingData = true

      const filteredRaData = this.dbRawData.filter(
        (obj) =>
          obj.timestamp.split('T')[1].slice(0, 5) === this.selectedDownloadTime
      )

      const dataDownload = await raAnalysisDownload(
        ...this.downloadInfo,
        this.selectedDownloadTime,
        filteredRaData,
        this.uploadFile
      )
      const { success, title, text, icon } = dataDownload

      this.loadingData = false

      if (!success) {
        // reset upload
        this.$refs.inputFile.value = null // 清除 input file value
        this.uploadFile = undefined // 清除要上傳的暫存
        this.uploadFileName = ''

        return this.$swal.fire({
          title,
          text,
          icon
        })
      }
    },

    handleChangeFiles (e) {
      if (e.target.files.length === 0) {
        this.uploadFile = undefined // 上傳後又取消，清除要上傳的暫存
        this.uploadFileName = ''
      }
      if (e.target.files.length === 1) {
        const [file] = e.target.files
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onloadend = (e) => {
          this.uploadFile = e.target.result
          this.uploadFileName = file.name
        }
        reader.onerror = (err) => console.log(err)
      }
    }
  },
  async mounted () {
    if (!this.factoryInfo.length) await this.asyncInfo()
  }
}
</script>

<style lang="scss" scoped>
.btnWrap {
  margin-left: auto;
  @media screen and (max-width: 425px) {
    width: 100%;
    text-align: center;
    margin-left: 8px;
  }
}

.tableContent {
  height: calc(100vh - 240px);
  overflow-y: auto;
  position: relative;
  @media screen and (max-width: 1024px) {
    height: calc(100vh - 275px);
  }
  @media screen and (max-width: 576px) {
    height: calc(100vh - 335px);
  }
  @media screen and (max-width: 425px) {
    height: calc(100vh - 425px);
  }
  @media screen and (max-width: 320px) {
    height: calc(100vh - 450px);
  }
}

.tableTitle {
  position: sticky;
  top: 0px;
  height: 35px;
  line-height: 35px;
  background-color: #f6f7f5;
  font-size: 15px;
  font-weight: 700;
  z-index: 10;
}

.timeText {
  cursor: pointer;
  font-size: 17px;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  letter-spacing: 1px;
  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
  @media screen and (max-width: 320px) {
    font-size: 13px;
  }
  &:hover {
    color: #2c8cda;
  }
}

.invWrap {
  border-bottom: 1px solid #dee2e6;
}

.invWrap:nth-last-child(1) {
  border-bottom: 0;
}

.pvWrap {
  border-bottom: 1px solid #dee2e6;
  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
  @media screen and (max-width: 320px) {
    font-size: 13px;
  }
}
.pvWrap:nth-last-child(1) {
  border-bottom: 0;
}

.invToggle {
  cursor: pointer;
  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
  @media screen and (max-width: 320px) {
    font-size: 13px;
  }
  &:hover {
    font-weight: 700;
    color: #2c8cda;
  }
}

.expandTableBtn {
  position: absolute;
  right: 20%;
  font-size: 13px;
  @media screen and (max-width: 960px) {
    right: 12%;
  }
  @media screen and (max-width: 700px) {
    right: 8%;
  }
  @media screen and (max-width: 650px) {
    transform: scale(0.9);
    right: 2%;
  }
  @media screen and (max-width: 320px) {
    transform: scale(0.7);
  }
  &:hover {
    color: #2c8cda;
  }
}

.raWarning {
  background-color: rgba(255, 49, 49, 0.15);
}

.toggleExpandBtn {
  &.btn-expand {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    letter-spacing: 1px;
    &:hover {
      color: #fff;
      background-color: darken(#17a2b8, 18%);
      border-color: darken(#17a2b8, 5%);
    }
    &:active {
      color: #fff;
      background-color: darken(#17a2b8, 20%);
      border-color: darken(#17a2b8, 5%);
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
  }

  &.btn-collapse {
    color: #6c757d;
    border-color: #6c757d;
    letter-spacing: 1px;
    &:hover {
      border-color: #17a2b8;
      color: #17a2b8;
      background-color: #fff;
    }
    &:active {
      border-color: #17a2b8;
      color: rgb(70, 70, 70);
      background-color: darken(#17a2b8, 25%);
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
</style>
