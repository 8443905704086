'use strict'

const ExcelJS = require('exceljs')

export const raAnalysisDownload = async (
  factoryName,
  date,
  time,
  raData,
  uploadFile
) => {
  const workbook = new ExcelJS.Workbook() // create excel

  await workbook.xlsx.load(uploadFile) // buffer
  // .then((result) => console.log(result))
  // .catch((err) => console.error(err))

  // console.log(workbook)
  // console.log(workbook.worksheets)
  // console.log(workbook.worksheets?.[0].name)

  // [ step 1 ] check worksheet name ----------
  const worksheet = workbook.getWorksheet('RA和PR值輸入欄位')
  if (!worksheet) {
    return {
      success: false,
      title: '上傳格式錯誤',
      text: '第一個工作表名稱 應為 RA和PR值輸入欄位',
      icon: 'error'
    }
  }

  // [ step 2 ] check row name ----------
  const row = worksheet.getRow(3)
  const titleList = row.values

  if (titleList[3] !== 'INV系統編號') {
    return {
      success: false,
      title: '上傳格式錯誤',
      text: '表格欄位 C3 應為 INV系統編號',
      icon: 'error'
    }
  }

  if (titleList[5] !== 'PV編號') {
    return {
      success: false,
      title: '上傳格式錯誤',
      text: '表格欄位 E3 應為 PV編號',
      icon: 'error'
    }
  }

  if (titleList[15] !== '日照計讀值') {
    return {
      success: false,
      title: '上傳格式錯誤',
      text: '表格欄位 O3 應為 日照計讀值',
      icon: 'error'
    }
  }

  if (titleList[16] !== 'RA計算值') {
    return {
      success: false,
      title: '上傳格式錯誤',
      text: '表格欄位 P3 應為 RA計算值',
      icon: 'error'
    }
  }

  // excelJS 提供的寫法但無法 return 值到外部
  // row.eachCell((cell, colNumber) => {
  //   if (colNumber === 16 && cell.value !== 'RA計算值') return false
  //   if (colNumber === 5 && cell.value !== 'PV編號') return false
  //   if (colNumber === 3 && cell.value !== 'INV系統編號') return false
  // })

  // // [ step 3 ] check column content ----------
  // const invNoList = raData.map((item) => item.invNo + 1)
  // const pvNoList = raData.map((item) => item.pvNo + 1)

  // const columnInv = worksheet.getColumn('C')
  // const columnInvList = columnInv.values.slice(4)

  // const columnPv = worksheet.getColumn('E')
  // const columnPvList = columnPv.values.slice(4)

  // // check columnInv (欄位 C) 需有資料
  // if (!columnInvList.length) {
  //   return {
  //     success: false,
  //     title: '上傳格式錯誤',
  //     text: '表格欄位 C 需填入 INV 編號',
  //     icon: 'error'
  //   }
  // }

  // // check columnPv (欄位 E) 需有資料
  // if (!columnPvList.length) {
  //   return {
  //     success: false,
  //     title: '上傳格式錯誤',
  //     text: '表格欄位 E 需填入 PV 編號',
  //     icon: 'error'
  //   }
  // }

  // // check columnInv INV 編號
  // for (let i = 0; i < invNoList.length; i++) {
  //   const columnInvString = columnInvList?.[i]
  //     ?.toString()
  //     .slice(0, 3)
  //     .toUpperCase()
  //   const columnInvNo = Number(columnInvList?.[i]?.toString().slice(4))
  //   const raDataInvNo = invNoList[i]

  //   if (columnInvString !== 'INV' || columnInvNo !== raDataInvNo) {
  //     return {
  //       success: false,
  //       title: '上傳格式錯誤',
  //       text: `表格欄位 C 序列有誤，C${i + 4} 應為 INV ${raDataInvNo}`,
  //       icon: 'error'
  //     }
  //   }
  // }

  // // check columnPv PV 編號
  // for (let i = 0; i < pvNoList.length; i++) {
  //   const columnPvString = columnPvList?.[i]
  //     ?.toString()
  //     .slice(0, 2)
  //     .toUpperCase()
  //   const columnPvNo = Number(columnPvList?.[i]?.toString().slice(2))
  //   const raDataPvNo = pvNoList[i]

  //   if (columnPvString !== 'PV' || columnPvNo !== raDataPvNo) {
  //     return {
  //       success: false,
  //       title: '上傳格式錯誤',
  //       text: `表格欄位 E 序列有誤，E${i + 4} 應為 PV${raDataPvNo}`,
  //       icon: 'error'
  //     }
  //   }
  // }

  // [ step 3 ] insert columnC invNo & columnE pvNo ----------
  const columnInv = worksheet.getColumn('C')
  const insertInvNoData = raData.map((item) => `INV ${item.invNo + 1}`)
  columnInv.header = [null, null, 'INV系統編號', ...insertInvNoData]

  const columnPv = worksheet.getColumn('E')
  const insertPvNoData = raData.map((item) => `PV ${item.pvNo + 1}`)
  columnPv.header = [null, null, 'PV編號', ...insertPvNoData]

  // [ step 4 ] insert columnO IRR data & columnP RA data ----------
  const columnIrr = worksheet.getColumn('O')
  const insertIrrData = raData.map((v) => v.IRR ?? '-')
  columnIrr.header = [null, null, '日照計讀值', ...insertIrrData]

  const columnRa = worksheet.getColumn('P')
  const insertRaData = raData.map((v) => v.RA ?? '-')
  columnRa.header = [null, null, 'RA計算值', ...insertRaData]

  // [ step 5 ] insert last column InvNo-PvNo ----------
  const LastColumn = worksheet.getColumn(titleList.length)
  const insertSystemData = insertInvNoData.map(
    (invNo, idx) => invNo + ' - ' + insertPvNoData[idx]
  )
  LastColumn.header = [null, null, '系統計算串列', ...insertSystemData]

  // [ step 6 ] download data ----------
  const attachment = await workbook.xlsx.writeBuffer()
  const url = window.URL.createObjectURL(new Blob([attachment]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${factoryName}_RA下載_${date}_${time}.xlsx`)
  link.click()

  // 清除暫存
  link.href = ''
  window.URL.revokeObjectURL(url)

  return { success: true }
}
